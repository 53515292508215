import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexHw6xinyNtOMeta } from "/vercel/path0/pages/mci/index.vue?macro=true";
import { default as engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta } from "/vercel/path0/pages/servicos/engenharia-manutencao-e-confiabilidade.vue?macro=true";
import { default as inspecoes1tbzPN2eAMMeta } from "/vercel/path0/pages/servicos/inspecoes.vue?macro=true";
import { default as s3i3TkBNG5lLZMeta } from "/vercel/path0/pages/servicos/s3i.vue?macro=true";
import { default as sftaF48TrgnQ4Meta } from "/vercel/path0/pages/servicos/sft.vue?macro=true";
import { default as indexYl15cOcadbMeta } from "/vercel/path0/pages/sobre/index.vue?macro=true";
export default [
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___pt",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/en",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___es",
    path: indexe9Brt5DfdhMeta?.path ?? "/es",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHw6xinyNtOMeta?.name ?? "mci___pt",
    path: indexHw6xinyNtOMeta?.path ?? "/mci",
    meta: indexHw6xinyNtOMeta || {},
    alias: indexHw6xinyNtOMeta?.alias || [],
    redirect: indexHw6xinyNtOMeta?.redirect,
    component: () => import("/vercel/path0/pages/mci/index.vue").then(m => m.default || m)
  },
  {
    name: indexHw6xinyNtOMeta?.name ?? "mci___en",
    path: indexHw6xinyNtOMeta?.path ?? "/en/mci",
    meta: indexHw6xinyNtOMeta || {},
    alias: indexHw6xinyNtOMeta?.alias || [],
    redirect: indexHw6xinyNtOMeta?.redirect,
    component: () => import("/vercel/path0/pages/mci/index.vue").then(m => m.default || m)
  },
  {
    name: indexHw6xinyNtOMeta?.name ?? "mci___es",
    path: indexHw6xinyNtOMeta?.path ?? "/es/mci",
    meta: indexHw6xinyNtOMeta || {},
    alias: indexHw6xinyNtOMeta?.alias || [],
    redirect: indexHw6xinyNtOMeta?.redirect,
    component: () => import("/vercel/path0/pages/mci/index.vue").then(m => m.default || m)
  },
  {
    name: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.name ?? "servicos-engenharia-manutencao-e-confiabilidade___pt",
    path: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.path ?? "/servicos/engenharia-manutencao-e-confiabilidade",
    meta: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta || {},
    alias: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.alias || [],
    redirect: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/engenharia-manutencao-e-confiabilidade.vue").then(m => m.default || m)
  },
  {
    name: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.name ?? "servicos-engenharia-manutencao-e-confiabilidade___en",
    path: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.path ?? "/en/servicos/engenharia-manutencao-e-confiabilidade",
    meta: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta || {},
    alias: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.alias || [],
    redirect: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/engenharia-manutencao-e-confiabilidade.vue").then(m => m.default || m)
  },
  {
    name: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.name ?? "servicos-engenharia-manutencao-e-confiabilidade___es",
    path: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.path ?? "/es/servicos/engenharia-manutencao-e-confiabilidade",
    meta: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta || {},
    alias: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.alias || [],
    redirect: engenharia_45manutencao_45e_45confiabilidadezaFH52EZV9Meta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/engenharia-manutencao-e-confiabilidade.vue").then(m => m.default || m)
  },
  {
    name: inspecoes1tbzPN2eAMMeta?.name ?? "servicos-inspecoes___pt",
    path: inspecoes1tbzPN2eAMMeta?.path ?? "/servicos/inspecoes",
    meta: inspecoes1tbzPN2eAMMeta || {},
    alias: inspecoes1tbzPN2eAMMeta?.alias || [],
    redirect: inspecoes1tbzPN2eAMMeta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/inspecoes.vue").then(m => m.default || m)
  },
  {
    name: inspecoes1tbzPN2eAMMeta?.name ?? "servicos-inspecoes___en",
    path: inspecoes1tbzPN2eAMMeta?.path ?? "/en/servicos/inspecoes",
    meta: inspecoes1tbzPN2eAMMeta || {},
    alias: inspecoes1tbzPN2eAMMeta?.alias || [],
    redirect: inspecoes1tbzPN2eAMMeta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/inspecoes.vue").then(m => m.default || m)
  },
  {
    name: inspecoes1tbzPN2eAMMeta?.name ?? "servicos-inspecoes___es",
    path: inspecoes1tbzPN2eAMMeta?.path ?? "/es/servicos/inspecoes",
    meta: inspecoes1tbzPN2eAMMeta || {},
    alias: inspecoes1tbzPN2eAMMeta?.alias || [],
    redirect: inspecoes1tbzPN2eAMMeta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/inspecoes.vue").then(m => m.default || m)
  },
  {
    name: s3i3TkBNG5lLZMeta?.name ?? "servicos-s3i___pt",
    path: s3i3TkBNG5lLZMeta?.path ?? "/servicos/s3i",
    meta: s3i3TkBNG5lLZMeta || {},
    alias: s3i3TkBNG5lLZMeta?.alias || [],
    redirect: s3i3TkBNG5lLZMeta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/s3i.vue").then(m => m.default || m)
  },
  {
    name: s3i3TkBNG5lLZMeta?.name ?? "servicos-s3i___en",
    path: s3i3TkBNG5lLZMeta?.path ?? "/en/servicos/s3i",
    meta: s3i3TkBNG5lLZMeta || {},
    alias: s3i3TkBNG5lLZMeta?.alias || [],
    redirect: s3i3TkBNG5lLZMeta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/s3i.vue").then(m => m.default || m)
  },
  {
    name: s3i3TkBNG5lLZMeta?.name ?? "servicos-s3i___es",
    path: s3i3TkBNG5lLZMeta?.path ?? "/es/servicos/s3i",
    meta: s3i3TkBNG5lLZMeta || {},
    alias: s3i3TkBNG5lLZMeta?.alias || [],
    redirect: s3i3TkBNG5lLZMeta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/s3i.vue").then(m => m.default || m)
  },
  {
    name: sftaF48TrgnQ4Meta?.name ?? "servicos-sft___pt",
    path: sftaF48TrgnQ4Meta?.path ?? "/servicos/sft",
    meta: sftaF48TrgnQ4Meta || {},
    alias: sftaF48TrgnQ4Meta?.alias || [],
    redirect: sftaF48TrgnQ4Meta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/sft.vue").then(m => m.default || m)
  },
  {
    name: sftaF48TrgnQ4Meta?.name ?? "servicos-sft___en",
    path: sftaF48TrgnQ4Meta?.path ?? "/en/servicos/sft",
    meta: sftaF48TrgnQ4Meta || {},
    alias: sftaF48TrgnQ4Meta?.alias || [],
    redirect: sftaF48TrgnQ4Meta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/sft.vue").then(m => m.default || m)
  },
  {
    name: sftaF48TrgnQ4Meta?.name ?? "servicos-sft___es",
    path: sftaF48TrgnQ4Meta?.path ?? "/es/servicos/sft",
    meta: sftaF48TrgnQ4Meta || {},
    alias: sftaF48TrgnQ4Meta?.alias || [],
    redirect: sftaF48TrgnQ4Meta?.redirect,
    component: () => import("/vercel/path0/pages/servicos/sft.vue").then(m => m.default || m)
  },
  {
    name: indexYl15cOcadbMeta?.name ?? "sobre___pt",
    path: indexYl15cOcadbMeta?.path ?? "/sobre",
    meta: indexYl15cOcadbMeta || {},
    alias: indexYl15cOcadbMeta?.alias || [],
    redirect: indexYl15cOcadbMeta?.redirect,
    component: () => import("/vercel/path0/pages/sobre/index.vue").then(m => m.default || m)
  },
  {
    name: indexYl15cOcadbMeta?.name ?? "sobre___en",
    path: indexYl15cOcadbMeta?.path ?? "/en/sobre",
    meta: indexYl15cOcadbMeta || {},
    alias: indexYl15cOcadbMeta?.alias || [],
    redirect: indexYl15cOcadbMeta?.redirect,
    component: () => import("/vercel/path0/pages/sobre/index.vue").then(m => m.default || m)
  },
  {
    name: indexYl15cOcadbMeta?.name ?? "sobre___es",
    path: indexYl15cOcadbMeta?.path ?? "/es/sobre",
    meta: indexYl15cOcadbMeta || {},
    alias: indexYl15cOcadbMeta?.alias || [],
    redirect: indexYl15cOcadbMeta?.redirect,
    component: () => import("/vercel/path0/pages/sobre/index.vue").then(m => m.default || m)
  }
]