

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{}')
  
  
  
  
  return options
}

