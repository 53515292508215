export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"keywords","content":"Inspeções, Preditiva, Manutenção, S3I, Sistema inteligente de Intervenção Industrial, SERVIÇOS TÉCNICOS DE INSPEÇÃO, MCI, Manutenção Condicionada a Inspeção, OBTENHA TODO O POTENCIAL NA SUA MANUTENÇÃO DE FORMA SIMPLES E ESTRUTURADA, SERVIÇOS DE ENGENHARIA DE MANUTENÇÃO & CONFIABILIDADE, SERVIÇOS TÉCNICOS DE INSPEÇÃO, SISTEMA FIXO DE TERMOGRAFIA, SFT, S3I, SUA PARCEIRA CONFIÁVEL EM SOLUÇÕES INDUSTRIAIS"},{"name":"author","content":"Pred Engenharia"},{"property":"og:type","content":"website"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:title","content":"Pred Engenharia"},{"name":"twitter:description","content":"A Pred Engenharia é uma provedora de soluções para gestão de ativos e processos, com foco na garantia da segurança, confiabilidade e produtividade das atividades. Atuando na indústria e na área de serviços desde 1993, suas inovadoras soluções são fornecidas ao mercado através de três áreas de negócios: Serviços Técnicos, Tecnologia da Informação e Automação Industrial."},{"name":"twitter:image","content":"https://www.predengenharia.com.br/30anos.webp"}],"link":[{"rel":"canonical","href":"https://www.predengenharia.com.br/"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"pt"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false